<template>
  <v-card elevation="2" color="background">
    <v-card-title>
      القوائم
      <v-spacer></v-spacer>

      <DefaultDialog
        buttonText="اضافة"
        title="اضافة قائمة"
        :openToEdit="openToEdit"
        editTitle="تعديل القائمة"
        :openToView="openToView"
        viewTitle="عرض البيانات"
        @onCloseDialog="onCloseDialog"
      >
        <NavigationDetail
          slot-scope="{ submit, closeDialog, isEdit, isView, isNew }"
          :submit="submit"
          :closeDialog="closeDialog"
          :afterSubmit="getNavigations"
          :itemId="itemId"
          :isEdit="isEdit"
          :isView="isView"
          :isNew="isNew"
        />
      </DefaultDialog>
    </v-card-title>
    <v-card-text class="flex-1">
      <!-- Filters -->
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="options.navigationName"
            flat
            hide-details="auto"
            label="بحث"
            outlined
            prepend-inner-icon="mdi-magnify"
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <languages-filter @change="changeLanguage" />
        </v-col>
      </v-row>

      <!-- Grid -->
      <v-col cols="12">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="navigations"
          :items-per-page="options.pageSize"
          :loading="$store.state.loading.show"
          :ripple="false"
          hide-default-footer
          loading-text="جار التحميل..."
          no-data-text="لا يوجد"
        >
          <template v-slot:[`item.controls`]="props">
            <v-btn
              small
              color="#78b200"
              @click="viewRow(props.item)"
              class="mr-1"
            >
              <v-icon color="#fff">mdi-eye</v-icon>
            </v-btn>

            <v-btn
              small
              color="#ffa000"
              @click="editRow(props.item)"
              class="mr-1"
            >
              <v-icon color="#fff">mdi-pencil-outline</v-icon>
            </v-btn>

            <v-btn
              small
              color="pink"
              @click="deleteRow(props.item)"
              class="mr-1"
            >
              <v-icon color="#fff">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>

      <!-- pagination -->
      <v-row class="justify-center">
        <v-col cols="6">
          <v-pagination v-model="options.pageIndex" :length="numberOfPages" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import qs from 'qs';
import LanguagesFilter from '@/components/LanguagesFilter.vue';
import { selectedLanguage } from '@/config/config';
import DefaultDialog from '@/components/DefaultDialog.vue';
import NavigationDetail from './curd/NavigationDetail.vue';
import { mapActions } from 'vuex';
import { showSnackbarFailMessage } from '@/utls/snackbar';

export default {
  components: {
    LanguagesFilter,
    NavigationDetail,
    DefaultDialog,
  },
  data() {
    return {
      sel: null,
      navigations: [],
      total: 0,
      headers: [
        { text: '', value: 'controls', sortable: false },
        { text: 'الأسم', value: 'language.name' },
        { text: 'النوع', value: 'navLanguage.name' },
        { text: 'الرابط', value: 'url' },
        { text: 'التاركت', value: 'target' },
        { text: 'الشكل', value: 'icon' },
      ],
      options: {
        pageIndex: 1,
        pageSize: 6,
        language: selectedLanguage,
        navigationName: '',
      },
      // for view edit and detail
      // ------------------------
      openToEdit: false,
      openToView: false,
      itemId: null,
      // ------------------------
    };
  },
  computed: {
    skipPerPage() {
      return this.options.pageSize * (this.options.pageIndex - 1);
    },
    numberOfPages() {
      const pages = Math.ceil(this.total / this.options.pageSize) || 1;
      return pages;
    },
  },
  created() {
    this.getNavigations();
  },
  methods: {
    ...mapActions('confirm', ['openConfirm']),
    deleteRow(item) {
      this.openConfirm({
        message: 'هل انت متأكد من حذف القائمة ؟',
        confirm: () => {
          return this.$http
            .delete('app/navigation/' + item.id, {})
            .then(() => {
              this.getNavigations();
              return true;
            })
            .catch((error) => {
              showSnackbarFailMessage('حدث خطأ اثناء عملية الحذف');
              return false;
            });
        },
      });
    },
    onCloseDialog() {
      this.openToEdit = false;
      this.openToView = false;
    },
    editRow(item) {
      this.openToEdit = true;
      this.itemId = item.id;
    },
    viewRow(item) {
      this.openToView = true;
      this.itemId = item.id;
    },

    changeLanguage(language) {
      this.options.language = language;
    },
    getNavigations() {
      this.$store.commit('loading/SET_SHOW', true);

      const requestConfig = {
        params: {
          skipCount: this.skipPerPage,
          maxResultCount: this.options.pageSize,
          language: this.options.language,
          navigationName: this.options.navigationName,
        },
        paramsSerializer: function (params) {
          const queryString = qs.stringify(params, { arrayFormat: 'brackets' });
          return queryString;
        },
      };

      this.$http
        .get('app/navigation/by-filters', requestConfig)
        .then((response) => {
          const { items, totalCount } = response.data;

          const resultData = items.reduce((p, c) => {
            const language = c.navigationLanguages[0];
            const navLanguage = c.navType && c.navType.navTypeLanguages[0];
            const data = [...p, { ...c, language, navLanguage }];
            return data;
          }, []);

          this.navigations = resultData;
          this.total = totalCount;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
        })
        .finally(() => this.$store.commit('loading/SET_SHOW', false));
    },
    searchUser() {},
  },
  watch: {
    options: {
      handler() {
        this.getNavigations();
      },
      deep: true,
    },
  },
};
</script>
